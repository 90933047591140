import * as React from 'react'

import moment from 'moment'

import { Button } from '../Button'
import { Modal } from '../Modal'
import Pagination from '../Pagination'
import RunwayForm from '../Profile/Runways/RunwayForm'
import { Table } from '../Table'

import { listProfileWithProfileStatus } from '../../services/profile'
import { listRunways, countRunways } from '../../services/runways'

import { useDynamicTable } from '../../hooks/useDynamicTable'
import { useFeatureFlags } from '../../hooks/useFeatureFlags'

import { roleTypes } from '../../utils/helpers'
import { createProfileNameColumn } from '../../utils/profile-name-column'
import {
  getRunwayWithExtraInfo,
  mapProfilesToOptions
} from '../../utils/runway-helpers'

import RunwayConsideredForColumn from './RunwayConsideredForColumn'
import RunwayDurationColumn from './RunwayDurationColumn'
import RunwayStartAtColumn from './RunwayStartAtColumn'

import { foundText } from './ActiveRunwaysListTable.module.css'

export default function ActiveRunwaysListTable ({ authData }) {
  const [modal, setModal] = React.useState(false)
  const [bookedProfiles, setBookedProfiles] = React.useState([])

  const { features } = useFeatureFlags()

  const isRunwayImprovementEnabled = features?.RELEASE_RUNWAY_IMPROVEMENTS

  React.useEffect(() => {
    async function getProfiles () {
      const { data } = await listProfileWithProfileStatus({
        params: {
          status: 'booked',
          populate: ['profile']
        }
      })

      setBookedProfiles(mapProfilesToOptions(data))
    }

    getProfiles()
  }, [])

  const defaultPagination = {
    pageSize: 50,
    current: 1
  }

  const {
    pagination,
    setPagination,
    onPaginationChange,
    onTableChange,
    data,
    setData,
    loading
  } = useDynamicTable(
    {
      defaultPagination,
      filterToFetchParam: {},

      fetchCount: () => {
        const params = {
          active: true
        }

        return countRunways({ params })
      },
      fetch: params => {
        const customParams = {
          ...params,
          active: true,
          populate: ['profile.profileStatus']
        }

        return listRunways({ params: customParams })
      }
    }
  )

  const onCreate = async () => {
    const params = {
      active: true,
      populate: ['profile.profileStatus']
    }
    setModal(false)
    const res = await listRunways({ params })
    setData(res.data)
    setPagination(pagination => ({
      ...pagination,
      total: pagination.total + 1
    }))
  }

  const close = React.useCallback(() => {
    setModal(false)
  }, [])

  const {
    role: { type: roleType }
  } = authData

  const canCreateRunways =
    roleType === roleTypes.bizdev || roleType === roleTypes.manager

  const { total } = pagination
  const dataWithExtraInfo = data.map(runway => getRunwayWithExtraInfo(runway))

  const columns = [
    createProfileNameColumn({
      dataIndex: 'profile'
    }),

    {
      title: 'Start',
      dataIndex: 'startAt',
      key: 'startAt',
      width: 180,
      render: value => <RunwayStartAtColumn startAt={value} />,
      sorter: true
    },

    {
      title: 'Duration',
      key: 'duration',
      defaultSortOrder: 'descend',
      render: value => (
        <RunwayDurationColumn
          endingDate={value.endingDate}
          endAt={value.endAt}
          description={value.description}
        />
      ),
      sorter: {
        compare: (a, b) => moment(a.endingDate).diff(b.endingDate)
      }
    },

    {
      title: 'Internal status',
      dataIndex: 'profile',
      key: 'benchStatus',
      render: value => value?.benchStatus
    },

    {
      title: 'Internal summary',
      dataIndex: 'profile',
      key: 'benchSummary',
      render: value => value?.benchSummary
    },
    {
      title: 'Considered for',
      dataIndex: 'profile',
      key: 'profileStatus.status',
      render: value => (value?.profileStatus?.status === 'reserved' && <RunwayConsideredForColumn profile={value} />)
    }
  ].filter(col => Boolean(col))

  return (
    <>
      <Modal width={'500px'} open={modal} centered footer={null}>
        <RunwayForm
          close={close}
          onCreate={onCreate}
          setEditMode={close}
          isSearchCandidateVisible
          bookedProfiles={bookedProfiles}
        />
      </Modal>

      {isRunwayImprovementEnabled && canCreateRunways && (
        <Button type="primary" onClick={() => setModal(true)}>
          New Runway
        </Button>
      )}
      <p className={foundText}>
        <b>{total}</b> found in total
      </p>
      <Pagination simple={true} onChange={onPaginationChange} {...pagination} />
      <Table
        name="activeRunwaysTable"
        showControls
        columns={columns}
        rowKey="id"
        dataSource={dataWithExtraInfo}
        loading={loading}
        onChange={onTableChange}
        pagination={false}
      />
      <Pagination simple={true} onChange={onPaginationChange} {...pagination} />
    </>
  )
}
