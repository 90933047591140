import React, { useCallback, useContext } from 'react'

import { ExclamationCircleOutlined } from '@ant-design/icons'

import RoleCandidatesContext from '../../providers/RoleCandidatesProvider/RoleCandidatesContext'
import { useAuth } from '../useAuth'

import { message } from '../../components/Message'
import { ProfileContext } from '../../components/Profile/ProfileContext'

import { App } from 'redesign/components/App'

import {
  bookRoleCandidate,
  closeRoleCandidate,
  deleteRoleCandidate,
  pitchRoleCandidate,
  reserveRoleCandidate,
  shortlistRoleCandidate,
  transferReservation
} from '../../services/roleCandidates'
import {
  hideRoleCandidateSuggestion,
  unHideRoleCandidateSuggestion
} from '../../services/roleCandidateSuggestions'

import { getProfileStatus } from '../../utils/profile-helpers'

import { icon } from './useRoleCandidates.module.css'

const snoozedProfileAlert =
  'This candidate is currently snoozed. Changing their status will end the snooze.'

const baseModalProps = {
  icon: <ExclamationCircleOutlined className={icon} />,
  okText: 'Confirm',
  okButtonProps: { 'data-testid': 'btn-confirm-action' },
  cancelText: 'Cancel',
  cancelButtonProps: { 'data-testid': 'btn-cancel-action' },
  onCancel () {}
}

const useRoleCandidates = profileId => {
  const { modal: { confirm } } = App.useApp()

  const { data: authData } = useAuth()
  const { loadRoleCandidates, loadPartnerRoles, ...state } = useContext(
    RoleCandidatesContext
  )
  const { setData } = useContext(ProfileContext)

  const {
    roleCandidates,
    setRoleCandidates,
    setBookBusy,
    setPitchBusy,
    setReserveBusy
  } = state

  const syncRoleCandidatesAndStatus = useCallback(
    async profileId => {
      await loadRoleCandidates(profileId)
      await getProfileStatus(profileId)
    },
    [loadRoleCandidates]
  )

  const handlePitchRoleCandidate = async (params, callback) => {
    setPitchBusy(true)
    try {
      const response = await pitchRoleCandidate(params)
      if (profileId) {
        await syncRoleCandidatesAndStatus(profileId)
      } else {
        await loadRoleCandidates()
      }
      if (callback) {
        await callback(response)
      }
      message.success('Pitched!')
    } catch (err) {
      message.error('Pitching failed!')
    } finally {
      setPitchBusy(false)
    }
  }

  const handleBookRoleCandidate = async (params, callback) => {
    setBookBusy(true)
    try {
      const { data } = await bookRoleCandidate(params)
      if (profileId) {
        await syncRoleCandidatesAndStatus(profileId)
      } else {
        await loadRoleCandidates()
      }
      if (callback) {
        await callback(data)
      }
      message.success('Booked!')
    } catch (err) {
      message.error('Booking failed!')
    } finally {
      setBookBusy(false)
    }
  }

  const handleReserveRoleCandidate = async params => {
    setReserveBusy(true)
    try {
      await reserveRoleCandidate(params)

      if (profileId) {
        await syncRoleCandidatesAndStatus(profileId)
      }

      message.success('Reserved!')
    } catch (err) {
      message.error('Reservation failed!')
    } finally {
      setReserveBusy(false)
    }
  }

  const unsnoozeProfileData = profile => {
    setData({
      ...profile,
      profileSnooze: { isSnoozed: false }
    })
  }

  const snoozedAlertMessage = alertMessage => (
    <span>
      {snoozedProfileAlert}
      <br />
      {alertMessage}
    </span>
  )

  const onPitch =
    ({ profile, partnerRole }, callback) =>
      e => {
        e.stopPropagation()
        if (!profile || !partnerRole) {
          message.warn('Please, select the Partner Role first.')
          return null
        }
        const isSnoozed = Boolean(profile?.profileSnooze?.isSnoozed)

        const alertMessage = `Are you sure you want to pitch ${profile.fullName || 'this profile'
        } for "${partnerRole.name}"?`
        const confirmationMessage = isSnoozed
          ? snoozedAlertMessage(alertMessage)
          : alertMessage

        confirm({
          ...baseModalProps,
          title: 'Pitch',
          content: confirmationMessage,
          async onOk () {
            await handlePitchRoleCandidate(
              {
                profile: profile.id,
                partnerRole: partnerRole.id,
                pitchedBy: authData.id
              },
              callback
            )
            unsnoozeProfileData(profile)
          }
        })
      }

  const onBook = ({ profile, partnerRole }, callback) => e => {
    e.stopPropagation()
    if (!profile || !partnerRole) {
      message.warn('Please, select the Partner Role first.')
      return null
    }

    const isSnoozed = Boolean(profile?.profileSnooze?.isSnoozed)
    const alertMessage = `Are you sure you want to book ${profile.fullName || 'this profile'
    } for "${partnerRole.name}"?`
    const confirmationMessage = isSnoozed
      ? snoozedAlertMessage(alertMessage)
      : alertMessage

    confirm({
      ...baseModalProps,
      title: 'Book',
      content: confirmationMessage,
      async onOk () {
        await handleBookRoleCandidate(
          {
            profile: profile.id,
            partnerRole: partnerRole.id,
            bookedBy: authData.id
          },
          callback
        )
        unsnoozeProfileData(profile)
      }
    })
  }

  const onReserve = ({ profile, partnerRole, origin }, callback) => e => {
    e.stopPropagation()
    if (!profile || !partnerRole) {
      message.warn('Please, select the Partner Role first.')
      return null
    }
    const isSnoozed = Boolean(profile?.profileSnooze?.isSnoozed)

    const alertMessage = `Are you sure you want to reserve ${profile.fullname || 'this profile'
    } for "${partnerRole.name}"?`
    const confirmationMessage = isSnoozed
      ? snoozedAlertMessage(alertMessage)
      : alertMessage

    confirm({
      ...baseModalProps,
      title: 'Reserve',
      content: confirmationMessage,
      async onOk () {
        await handleReserveRoleCandidate({
          profile: profile.id,
          partnerRole: partnerRole.id,
          reservedBy: authData.id,
          origin,
        })

        unsnoozeProfileData(profile)

        if (callback) {
          await callback()
        }
      }
    })
  }

  const onShortlist = async (roleCandidate, callback) => {
    const shortlisted = {
      ...roleCandidate,
      shortlistedBy: authData.id
    }
    try {
      await shortlistRoleCandidate(shortlisted)
      if (profileId) {
        await syncRoleCandidatesAndStatus(profileId)
      }
      message.success('Shortlisted!')

      if (callback) {
        await callback()
      }
    } catch (error) {
      message.error(error?.message)
    }
  }

  const onHide = (roleSuggestion, updateRoleCandidates) => e => {
    e.stopPropagation()
    const { profile, partnerRole } = roleSuggestion
    if (!profile || !partnerRole) {
      message.warn('Please, select the Partner Role first.')
      return null
    }
    confirm({
      ...baseModalProps,
      title: 'Hide',
      content: `Are you sure you want to hide ${profile.fullName}?`,
      async onOk () {
        await hideRoleCandidateSuggestion(roleSuggestion.id)
        await updateRoleCandidates()
      }
    })
  }

  const onUnHide = (roleSuggestion, updateRoleCandidates) => e => {
    e.stopPropagation()
    const { profile, partnerRole } = roleSuggestion
    if (!profile || !partnerRole) {
      message.warn('Please, select the Partner Role first.')
      return null
    }
    confirm({
      ...baseModalProps,
      title: 'UNHide',
      content: `Are you sure you want to unhide ${
        profile.fullName || 'this profile'
      } for "${partnerRole.name}"?`,
      async onOk () {
        await unHideRoleCandidateSuggestion(roleSuggestion.id)
        await updateRoleCandidates()
      }
    })
  }

  const handleCloseRoleCandidate = async (roleCandidate, closeReason) => {
    const { id, shortlistedAt } = roleCandidate
    const { data } = await closeRoleCandidate({
      id,
      roleCandidateCloseReason: closeReason,
      closedBy: authData.id,
      shortlistedAt
    })
    const { closedAt } = data
    setRoleCandidates(
      roleCandidates?.map(candidate =>
        candidate.id === roleCandidate.id
          ? { ...candidate, closedAt }
          : candidate
      )
    )
    if (profileId) {
      await syncRoleCandidatesAndStatus(profileId)
    }
  }

  const handleDeleteRoleCandidate = async id => {
    await deleteRoleCandidate(id)
    setRoleCandidates(roleCandidates?.filter(candidate => candidate.id !== id))
    if (profileId) {
      await syncRoleCandidatesAndStatus(profileId)
    }
  }

  const handleTransferReservation = async ({ roleCandidateId, newPartnerRoleId, origin }) => {
    await transferReservation({ roleCandidateId, newPartnerRoleId, origin })

    if (profileId) {
      await syncRoleCandidatesAndStatus(profileId)
    }
  }

  const onDeleteRoleCandidate = ({ id }) => {
    confirm({
      ...baseModalProps,
      title: 'Are you sure you want to delete this Role Candidate?',
      content: (
        <span>
          If you only need to revert a Pitch, Book, or Close action, then you
          may contact support to revert it.
          <br />
          <b>
            Else, if you Delete, you will lose all its meta-data permanently.
          </b>
        </span>
      ),
      icon: <ExclamationCircleOutlined className={icon} />,
      okText: 'YES, DELETE',
      okButtonProps: { 'data-testid': 'btn-confirm-action-delete' },
      cancelText: 'NO, KEEP',
      onOk () {
        handleDeleteRoleCandidate(id)
      },
      onCancel () {}
    })
  }

  return {
    state,
    actions: {
      loadRoleCandidates,
      loadPartnerRoles,
      syncRoleCandidatesAndStatus,
      onPitch,
      onBook,
      handleCloseRoleCandidate,
      onReserve,
      onShortlist,
      onHide,
      onUnHide,
      onDeleteRoleCandidate,
      unsnoozeProfileData,
      handleTransferReservation
    }
  }
}

export default (function RoleCandidatesSingleton () {
  if (!RoleCandidatesSingleton.instance) {
    RoleCandidatesSingleton.instance = useRoleCandidates
  }
  return RoleCandidatesSingleton.instance
})()
