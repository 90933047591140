import React from 'react'

import { AuthConsumer } from '../providers/AuthProvider'

import Layout from '../components/Layout'
import RunwaysScreen from '../components/RunwaysScreen'
import SEO from '../components/SEO'

const RunwaysPage = () => (
  <Layout menuSelectedKeys={['runways']}>
    <SEO title="Runways" />

    <AuthConsumer>
      {({ isLoading, data }) => {
        if (isLoading) {
          return <div>Loading...</div>
        }

        if (!data) {
          return <div>Not logged in</div>
        }

        const isUserManager = data.role.type.toLowerCase() === 'manager'

        return <RunwaysScreen authData={data} isUserManager={isUserManager} />
      }}
    </AuthConsumer>
  </Layout>
)

export default RunwaysPage
