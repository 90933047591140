import { getChangedValues, castNumber, filledObjectProps } from './helpers'

export function mapPartnerRolesToSelectOptions (data = []) {
  return data.map(role => {
    let skills = ''

    if (role?.roleSkills?.length > 0) {
      skills =
        ' - ' +
        role?.roleSkills
          ?.slice(0, 3)
          .map(skill => skill?.skill?.title)
          .join(', ')
    }

    return {
      value: role.id,
      label: `${role.partner?.name}: ${role.name} (id: ${role.id})${skills}`
    }
  })
}

export function getChangedPartnerRoleFields (fields, initialValues, timezone) {
  const {
    timezoneOffsetRange,
    partner,
    recruiter,
    bizdevUser,
    active,
    ...restValues
  } = fields
  delete fields.requiredSkills
  delete fields.optionalSkills

  const changedValues = getChangedValues(fields, initialValues)

  const normalizedFields = {
    timezoneOffsetRange: {
      start: timezoneOffsetRange[0] + timezone.offset,
      end: timezoneOffsetRange[1] + timezone.offset,
    },
    partner: castNumber(partner),
    recruiter: castNumber(recruiter),
    bizdevUser: castNumber(bizdevUser),
    active: active
  }

  Object.keys(changedValues).forEach(key => {
    changedValues[key] = Object.keys(normalizedFields).includes(key)
      ? normalizedFields[key]
      : changedValues[key]

    // We need to handle this special case because the Role form sends
    // only 'timezoneOffsetRange' field
    // and not 'timezoneOffsetStart' or 'timezoneOffsetStart' which are required to be passed with API request.
    if (key === 'timezoneOffsetRange') {
      const startOffset = normalizedFields.timezoneOffsetRange.start
      const endOffset = normalizedFields.timezoneOffsetRange.end

      changedValues.timezoneOffsetStart = startOffset
      changedValues.timezoneOffsetEnd = endOffset

      // Now we reset the field to contain an array of offset numbers as it's expected by API
      changedValues.timezoneOffsetRange = [startOffset, endOffset]
    }
  })

  const extraKeys = [
    'additionalTechInterviewers',
    'bizdevStage',
    'coreSkills',
    'culturalConsiderations',
    'lengthOfEngagement',
    'numDevs',
    'roleFitApprover',
    'seniority'
  ]

  const extra = {}
  extraKeys.forEach(key => {
    extra[key] = changedValues[key] ?? restValues[key]
    delete changedValues[key]
  })

  changedValues.extra = filledObjectProps(extra)

  return changedValues
}
